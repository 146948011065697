import tw from '../../lib/tailwind';

const ThemeCore = {
  fullCenter: tw`flex justify-center w-full h-full items-center`,
  roundedWithShadow: {
    borderRadius: 10,
    shadowColor: 'rgba(0,0,0,0.4)',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 1,
    shadowBlur: 10,
    shadowRadius: 10,
  },
};

const Theme = {
  modal: {
    styles: {
      ...ThemeCore,
      container: [
        ThemeCore.fullCenter,
        {
          backgroundColor: '#333',
        },
      ],

      content: [
        tw`flex justify-center max-w-80 max-h-80 items-center`,
        ThemeCore.roundedWithShadow,
        {
          backgroundColor: '#fff',
          padding: 20,
        },
      ],

      overlay: [
        tw`absolute flex left-0 top-0 right-0 bottom-0`,
        {
          backgroundColor: 'rgba(0,0,0,0.4)',
        },
      ],
    },
  },
};

export default Theme;
