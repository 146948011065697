module.exports = {
  darkMode: 'class',
  content: ['./src/**/*.{js,jsx,ts,tsx}'],
  theme: {
    theme: {
      colors: {
        transparent: 'transparent',
        current: 'currentColor',
        white: '#ffffff',
        purple: '#3f3cbb',
        midnight: '#121063',
        metal: '#565584',
        green: '#006600',
        tahiti: '#3ab7bf',
        silver: '#ecebff',
        'bubble-gum': '#ff77e9',
        bermuda: '#78dcca',
      },
    },
    extend: {
      keyframes: {
        reveal: {
          '0%': {
            opacity: '0',
          },
          '100%': {
            opacity: '1',
          },
        },
        pop: {
          '0%': {
            transform: 'scale(1)',
          },
          '75%': {
            transform: 'scale(1.25)',
          },
          '100%': {
            transform: 'scale(1)',
          },
        },
      },
      animation: {
        reveal: 'reveal 200ms forwards',
        pop: 'pop 500ms ease-out forwards',
      },
    },
  },
  plugins: [],
};
