import { View, Button } from 'react-native';

import Theme from './config/Theme';

const styles = Theme.modal.styles;

const AppWorkspace = () => {
  return (
    <View style={styles.container}>
      <Button title="Test Modal" onPress={() => {}} />
    </View>
  );
};

export default function App() {
  return <AppWorkspace />;
}
